.btngrd {
  flex: 1 1 auto;
  margin: 10px;
  padding: 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 5px #eee;
  border-radius: 10px;
}

.btngrd:hover {
  background-position: right center; /* change the direction of the change here */
}

.btngrd-1 {
  background-image: linear-gradient(
    to right,
    #f6d365 0%,
    #fda085 51%,
    #f6d365 100%
  );
}

.btngrd-2 {
  background-image: linear-gradient(
    to right,
    #fbc2eb 0%,
    #a6c1ee 51%,
    #fbc2eb 100%
  );
}

.btngrd-3 {
  background-image: linear-gradient(
    to right,
    #84fab0 0%,
    #8fd3f4 51%,
    #84fab0 100%
  );
}

.btngrd-4 {
  background-image: linear-gradient(
    to right,
    #a1c4fd 0%,
    #c2e9fb 51%,
    #a1c4fd 100%
  );
}

.btngrd-5 {
  background-image: linear-gradient(
    to right,
    #ffecd2 0%,
    #fcb69f 51%,
    #ffecd2 100%
  );
}

.main-area {
  background-color: transparent;
  overflow: hidden;
  width: 100%;
}

.splash-screen {
  -webkit-animation: splashAnim 22s linear infinite;
  animation: splashAnim 22s linear infinite;
}

.center-xy {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.login-footer {
  position: absolute;
  padding: 0 10px;
  padding-top: 120px;
  color: #676767;
  font-size: 0.75em;
  font-weight: 400;
  line-height: 15px;
  position: sticky;
  text-align: center;
  max-width: 480px;
  width: 100%;
  z-index: 2;
  top: 20%;
}

figure {
  margin: 0 0 1rem;
}

.font-15 {
  color: #2c2c2c;
  font-size: 1.5em;
  font-weight: 700;
}

.text-white {
  color: #fff !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.cxy,
.refer-button-copy {
  align-items: center;
  display: flex;
  justify-content: center;
}
.loginbtn11 {
  margin: 20px;
}
.loginbtn11 {
  color: #fff;
  width: 100px;
  padding: 5px 15px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  text-align: center;
  justify-content: center;
}

/* 11 */
.loginbtn11 {
  border: 1px solid #21ebff;
  color: #21ebff;
  box-shadow: 0 0 5px #21ebff, 0 0 5px #21ebff inset;
  transition: all 0.3s ease;
}
.loginbtn11:hover {
  text-shadow: 0 0 20px #21ebff, 0 0 50px rgba(33, 235, 255, 0.9),
    0 0 75px rgba(33, 235, 255, 0.8), 0 0 76px rgba(33, 235, 255, 0.7),
    0 0 77px rgba(33, 235, 255, 0.6), 0 0 78px rgba(33, 235, 255, 0.5),
    0 0 79px rgba(33, 235, 255, 0.4), 0 0 80px rgba(33, 235, 255, 0.3),
    0 0 85px rgba(33, 235, 255, 0.2), 0 0 99px rgba(33, 235, 255, 0.1);
}

element.style {
  width: 85%;
  height: 70px;
  border-radius: 5px;
}
.mobile::placeholder {
  color: #fff;
  opacity: 100; /* Firefox */
}
.neonbtn {
  background-color: transparent !important;
  color: #fff;
  border-radius: 10px;
  height: 50px;
}
.neonshadow {
  border: 1px solid #21ebff;
  color: #21ebff;
  box-shadow: 0 0 5px #21ebff, 0 0 5px #21ebff inset;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.hidden {
  display: none !important;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.cxy,
.refer-button-copy {
  align-items: center;
  display: flex;
  justify-content: center;
}

.refer-button {
  border: none;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 700;
  height: 48px;
  padding: 0 22px;
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
}

.refer-button:hover {
  color: white !important;
  text-decoration: none;
}

.bg-green {
  background-color: #0db25b;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
input[name="mobile"] {
  border: 1px solid #d8d6de;
}
input[name="mobile"]:focus {
  border-color: #7367f0;
}

.Login-button {
  border: none;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 500;
  height: 48px;
  padding: 0 22px;
  color: #fff;
  background-color: #0db25b;
  width: 85%;
  font-weight: 900;
  /* letter-spacing: 1px; */
  text-transform: uppercase;
}
