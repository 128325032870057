.headerContainer {
  background-image: linear-gradient(rgb(5, 9, 14), rgb(4, 21, 62)) !important;
  max-width: 480px;
  width: 100%;
  position: fixed;
  height: 60px;
  padding: 0 10px;
  top: 0px;
  z-index: 2;
  align-items: center;
  display: flex;
  padding-top: 0px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-left: 10px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
  border-bottom: 1px solid #21ebff;
}

.sideNavIcon {
  overflow: hidden;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}

.menu_items {
  position: absolute;
  right: 10px;
  align-items: center;
  display: flex;
}

.sideNavIcon > img {
  height: 14px;
  width: 16.5px;
  margin-bottom: 14px;
}

.navLogo > img {
  height: 4rem;
  width: 4rem;
}

.menu_items > .box {
  align-items: center;
  display: flex;
}

.menu_items > .box {
  border: 1px solid #21ebff;
  border-radius: 3px;
  height: 30px;
  position: relative;
  text-decoration: none;
  min-width: 90px;
  color: #fff;
  box-shadow: 0 0 5px #21ebff, 0 0 5px #21ebff inset;
}

.moneyIcon_container {
  overflow: hidden;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}

.moneyBox_add {
  background-color: #21ebff;
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 17px;
}
.referBox_add {
  bottom: 0px;
  position: relative;
  right: 0px;
  top: 0px;
  /*height: 95%;*/
  width: 25px;
  border-radius: 50%;
  border: 2px solid #f4bc41;
  color: #f4bc41;
  margin-left: 5px;

  box-shadow: rgb(244 188 65) 0px 0px 0.2rem;
  text-align: center;
}

.moneyIcon_container > img {
  width: 20px;
  margin-left: 4px;
}

.moneyBox_header {
  color: #fff;
  font-size: 0.6em;
  font-weight: bold;
  text-transform: uppercase;
}
.neonshadow {
  border: 1px solid #21ebff;
  color: #21ebff;
  box-shadow: 0 0 5px #21ebff, 0 0 5px #21ebff inset;
}

.moneyBox_text {
  color: #fff;
  font-size: 0.8em;
  font-weight: bold;
  position: relative;
  bottom: 4px;
}
.login_btn {
  color: blue;
  font-size: 1em;
  font-weight: 900;
  position: relative;
  bottom: 0px;
  /* padding-left: 151px; */
}

.moneyBox_add > img {
  height: 9px;
  width: 9px;
  margin-left: 6px;
}

.buttoncolor {
  background-image: linear-gradient(
    rgb(61, 128, 197),
    rgb(37, 61, 118)
  ) !important;
  color: black !important;
  border: 2px solid #f4bc41 !important;
  border-radius: 14px;
}
